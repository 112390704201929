<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Bank Masuk : {{TransactionNumber}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                     <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Regional</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Region" class="pb-3" :options="RegionData" v-model="Region" />
                            <label id="errorRegion" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Transaksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="TransactionDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorTransactionDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">No. Transaksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="TransactionNumber" v-model="TransactionNumber" class="font-weight-bold"/>
                            <label id="errorTransactionNumber" class="form-error" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kontak</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Contact" class="pb-3" :options="ContactData" v-model="Contact" />
                            <label id="errorContact" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Deskripsi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Desc" v-model="Desc" class="font-weight-bold" readonly/>
                            <label id="errorDesc" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">COA</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="COA" class="pb-3" :options="COAData" v-model="COA" />
                            <label id="errorCOA" class="form-error" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Mata Uang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Currency" class="font-weight-bold" :options="CurrencyData" :value.sync="Currency"/>
                            <label id="errorCurrency" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Saldo</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="Amount" v-model="Amount" class="font-weight-bold" readonly/>
                            <label id="errorAmount" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                            <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kurs</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="ExchangeRate" v-model="ExchangeRate" class="font-weight-bold" @change="onChangeSaldo()" min="0"/>
                            <label id="errorExchangeRate" class="form-error" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Valas</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="ForeignCurrency" v-model="ForeignCurrency" class="font-weight-bold" readonly/>
                            <label id="errorForeignCurrency" class="form-error" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Detail Bank Masuk</h4></label>
                            <hr>
                            <datasource ref="detailDataSource" :data="this.DetailGridData" :schema-model-fields="this.DetailSchemaModel"
                                :aggregate="[
                                    { field: 'amount', aggregate: 'sum' },
                                    { field: 'foreign_currency', aggregate: 'sum' },
                                ]"
                            />

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'detailDataSource'"
                                        :editable="{createAt: 'bottom'}"
                                        :scrollable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :toolbar="['create']"
                                        :cellClose="cellClose"
                                        :resizable="true"
                                        :remove="remove"
                                        >

                                <kendo-grid-column  :field="'chart_of_account_id'"
                                                    :title="'Akun'"
                                                    :width="200"
                                                    :editor= "COADropDownEditor"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'chart_of_account_name'"
                                                    :title="'Nama Akun'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'currency'"
                                                    :title="'Currency'"
                                                    :width="200"
                                                    :editor= "currencyDropDownEditor"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'amount'"
                                                    :title="'Saldo'"
                                                    :width="200"
                                                    :format="'{0:N2}'"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="`<div style='float: right'>#=  kendo.toString(sum, 'n2') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'exchange_rate'"
                                                    :title="'Kurs'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                <kendo-grid-column  :field="'foreign_currency'"
                                                    :title="'Valas'"
                                                    :width="200"
                                                    :format="'{0:N2}'"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="`<div style='float: right'>#=  kendo.toString(sum, 'n2') #</div>`"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'division'"
                                                    :title="'Divisi'"
                                                    :width="200"
                                                    :editor= "DivisionDropDownEditor"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                        
                                <kendo-grid-column  :field="'notes'"
                                                    :title="'Catatan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'budgeting'"
                                                    :title="'Budgeting'"
                                                    :width="200"
                                                    :editor= "BudgetingDropDownEditor"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'sub_project'"
                                                    :title="'Sub Proyek'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :title="'&nbsp;'"
                                                    :attributes="{ class: 'k-text-center' }"
                                                    :command="['destroy']"
                                                    :width="200"></kendo-grid-column>
                                
                            </kendo-grid>
                        </div>
                    </CRow>
                    <div v-if="this.View.toString() == 'false'" style="width:100%">
                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/bank-in')"> Close </CButton>
                    </div>
                </div>
                <bank-in-pdf ref="pdf" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import bankInServices from '../Script/BankInService';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';
import bankInPDF from '../../../../assets/template/BankInPdfTemplate.vue';

export default {
    name: 'ProjectFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'bank-in-pdf' : bankInPDF
    },
    async mounted () {
        if(this.FormType == 'Add'){
            this.TransactionInvPaymentId = this.Id,
            this.TransactionNumber = 'Auto';
            this.RegionData = await bankInServices.getRegionQuery();
            this.Region = '';

            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.TransactionDate = dateNow;

            this.ContactData = await bankInServices.getContactQuery();;
            this.Contact = null;
            this.Desc = 'Bank Masuk';
            this.COAData = await bankInServices.getCOAQuery();
            this.COA = '';
            this.CurrencyData = await bankInServices.getCurrency();
            this.Currency = 'IDR';
            this.ExchangeRate = 1;
            this.ForeignCurrency = 0;
            this.Amount = 0;
            this.Note = '';
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            this.DivisionData = await globalfunc.globalDropdown('ddl_finance_division');
            this.BudgetingData = await globalfunc.globalDropdown('ddl_finance_budgeting');
        
            if(this.Type == 'InvoicePayment'){
                //base Data Using Invoice Payment Data
                var invoicePaymentData = await bankInServices.getInvoicePaymentQuery(this.Id);
                this.COA = this.COAData.find(c => c.value == "114101");
                this.Contact= this.ContactData.find(c => c.value == invoicePaymentData.customer_id);

                //grid
                this.DetailGridData = bankInServices.setInvoicePaymentData(invoicePaymentData);
                this.noteBuilder(false);
            }
            else{
                this.COA = '';
                this.Contact = null;
                this.DetailGridData = [];
            }
            this.onChangeSaldo();

            this.SaveType = 'Add';
            this.View = false;
        }
        else{
            document.getElementById('TransactionNumber').readOnly = true;
            
            var data = await bankInServices.getDetailBankIn(this.Id);

            this.TransactionNumber = data.transaction_number;
            this.RegionData = await bankInServices.getRegionQuery();
            this.Region = this.RegionData.find(c => c.value == data.region_id);

            this.TransactionDate = data.transaction_date;

            this.ContactData = await bankInServices.getContactQuery();;
            this.Contact = this.ContactData.find(c => c.value == data.contact_id);
            this.Desc= data.desc;
            this.COAData= await bankInServices.getCOAQuery();
            this.COA = this.COAData.find(c => c.value == data.chart_of_account_id);
            this.CurrencyData = await bankInServices.getCurrency();
            this.Currency = data.currency;
            this.ExchangeRate = data.exchange_rate;
            this.ForeignCurrency = data.amount;
            this.Amount = data.foreign_currency;
            this.Note = data.notes;
            this.StatusData = this.StatusData = await globalfunc.globalDropdown('ddl_status_journal');
            this.Status = data.status;

            this.DivisionData = await globalfunc.globalDropdown('ddl_finance_division');
            this.BudgetingData = await globalfunc.globalDropdown('ddl_finance_budgeting');

            this.SaveType = 'Edit';

            //grid
            this.DetailGridData = globalfunc.objectToArrayConverter(data.bank_mutation_detail, "Finance-DetailDataSave");
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Type: this.$route.params.type,
            Error : 0,

            //Detail
            TransactionNumber: '',
            RegionData: [],
            Region: '',
            TransactionDate: '',
            ContactData: [],
            Contact: null,
            Desc: '',
            COAData: [],
            COA: '',
            CurrencyData: [],
            Currency: '',
            Note: '',
            StatusData: [],
            Status: '',
            Amount: 0,
            ExchangeRate: 0,
            ForeignCurrency: 0,
            DivisionData: [],
            BudgetingData: [],
            
            TransactionInvPaymentId: '',

            //grid
            DetailGridData: [],
            DetailSchemaModel: {
                chart_of_account_id: { type: "string", editable: true },
                chart_of_account_name: { type: "string", editable: false },
                currency: { type: "string", editable: true },
                amount: { type: "number", editable: true },
                exchange_rate: { type: "number", editable: true, validation: {min:1} },
                foreign_currency:  { type: "number", editable: false },
                division: { type: "string", editable: true },
                notes: { type: "string", editable: true },
                budgeting: { type: "string", editable: true },
                sub_project: { type: "string", editable: true },
            },
        }
    },
    methods: {
        remove(e){
            var grid = this.$refs.gridItem.kendoWidget();
            var data = grid.dataSource._data;
            const newdata = data.filter(item => item.uid !== e.model.uid);
            var foreign_currency = 0;

            if(newdata.length > 0){
                for (let i = 0; i < newdata.length; i++) {
                    foreign_currency = foreign_currency + newdata[i].foreign_currency;
                }
            }

            this.ForeignCurrency = foreign_currency;
            this.Amount = (this.ForeignCurrency / this.ExchangeRate);
            this.noteBuilder(true);
        },
        cellClose(e){
            var dataItem = e.sender.dataItem($(e.container).parent());
            
            if(dataItem.currency == ""){
                dataItem.currency = "IDR";
            }

            if(dataItem.currency == "IDR"){
                dataItem.exchange_rate = 1;
            }

            if(dataItem.currency != "IDR" && dataItem.exchange_rate <= 0){
                this.$swal("Error", "Kurs tidak boleh lebih kecil dari 0", "error");
                dataItem.exchange_rate = 1;
            }

            var COAData = this.COAData.find(c => c.value == dataItem.chart_of_account_id);
            
            dataItem.chart_of_account_name = COAData.label.replace(' (', '').replace(')', '').replace(dataItem.chart_of_account_id, '');
            dataItem.foreign_currency = dataItem.exchange_rate * dataItem.amount;
            
            if(dataItem.division == "" || dataItem.division == null){
                dataItem.division = "-";
            }
            if(dataItem.budgeting == "" || dataItem.budgeting == null){
                dataItem.budgeting = "-";
            }

            var grid = this.$refs.gridItem.kendoWidget();
            this.DetailGridData = globalfunc.objectToArrayConverter(grid.dataSource._data, "Finance-DetailData");
           
            this.onChangeSaldo();
            this.noteBuilder(false);
        },
        COADropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.COAData
                    }
                });
        },
        currencyDropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.CurrencyData
                    },
                });
        },
        async DivisionDropDownEditor(container, options){
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.DivisionData
                    },
                });
        },
        async BudgetingDropDownEditor(container, options){
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.BudgetingData
                    },
                });
        },
        onChangeSaldo(){
            var foreign_currency = 0;

            if(this.Currency == ""){
                this.Currency = "IDR";
            }

            if(this.Currency == "IDR"){
                this.ExchangeRate = 1;
            }
            
            if(this.Currency != "IDR" && this.ExchangeRate <= 0){
                this.$swal("Error", "Kurs tidak boleh lebih kecil dari 0", "error");
                this.ExchangeRate = 1;
            }

            if(this.DetailGridData.length > 0){
                for (let i = 0; i < this.DetailGridData.length; i++) {
                    foreign_currency = foreign_currency + this.DetailGridData[i].foreign_currency;
                }
            }
            this.ForeignCurrency = foreign_currency;
            
            this.Amount = (this.ForeignCurrency / this.ExchangeRate);
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.Region == '' || this.Region == null){
                this.errorShow('errorRegion');
            }
            if(this.TransactionDate == '' || this.TransactionDate == null){
                this.errorShow('errorTransactionDate');
            }
            if(this.COA == '' || this.COA == null){
                this.errorShow('errorCOA');
            }
            if(this.DetailGridData.length == 0){
                this.$swal("Error", "Detail bank masuk harus diisi terlebih dahulu", "error");
                this.Error++;
            }
            if (this.ExchangeRate == 0 || this.Amount == null){
                this.errorShow('errorExchangeRate');
            }
        },
        async saveClick(){
            this.inputValidation();
            
            if(this.Error == 0){
                var contact = this.Contact == null ? null : this.Contact.value;

                var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
                var newData = globalfunc.objectToArrayConverter(gridData, "Finance-DetailDataSave");
                this.onChangeSaldo();

                const bankInData = {
                    status: this.Status,
                    region_id: this.Region.value,
                    transaction_date: this.TransactionDate,
                    transaction_number: this.TransactionNumber,
                    contact_id: contact,
                    desc: this.Desc,
                    chart_of_account_id: this.COA.value,
                    chart_of_account_name: this.COA.label.replace(' (', '').replace(')', '').replace(this.COA.value, ''),
                    currency: this.Currency,
                    amount: this.ForeignCurrency,
                    exchange_rate: parseFloat(this.ExchangeRate),
                    foreign_currency: this.Amount,
                    notes: this.Note,
                    inv_payment_id: parseInt(this.TransactionInvPaymentId),
                    purchasing_inv_payment_id: null,
                    new_bank_mutation_detail: newData
                };

                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : bankInData
                    }
                    
                    bankInServices.addBankIn(variables).then(res => {
                        this.$loading(false);
                        var id = res.data.CreateBankInMutation.data.id;
                        
                        this.$swal(sweet_alert.success_save_print).then((result) => {
                            if (result.isConfirmed == true) {
                                this.$refs.pdf.generatePDF(id);
                                this.View = true;
                            }else if (result.isDenied) {
                                const routeData = this.$router.resolve({name: 'Bank In Html Template', params: { id:id }});
                                window.open(routeData.href, '_blank');
                                this.View = true;
                            }else{
                                this.$router.push({ path: '/bank-in' });
                            }
                        });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : parseInt(this.Id),
                        data : bankInData
                    }
                    
                    bankInServices.editBankIn(variables).then(res => {
                        this.$loading(false);

                        this.$swal(sweet_alert.update_save_print).then((result) => {
                            if (result.isConfirmed == true) {
                                this.$refs.pdf.generatePDF(variables.id);
                                this.View = true;
                            }else if (result.isDenied) {
                                const routeData = this.$router.resolve({name: 'Bank In Html Template', params: { id:variables.id }});
                                window.open(routeData.href, '_blank');
                                this.View = true;
                            }else{
                                this.$router.push({ path: '/bank-in' });
                            }
                        });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        noteBuilder(remove){
            var data = [];
            var notes = "";

            if (remove){
                var grid = this.$refs.gridItem.kendoWidget();
                data = grid.dataSource._data;
            } else {
                data = this.DetailGridData;
            }
            
            if(data.length > 0){
                for (let i = 0; i < data.length; i++) {
                    notes = notes + data[i].notes;

                    if(!(i == (data.length - 1))){
                        notes = notes + " \n";
                    }
                }
            }
            
            this.Note = notes;  
        }   
    }   
}
</script>

<style scoped>
</style>